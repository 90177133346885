<template>
  <div id="editPage">
    <div class="flex flex-row mb-2.5">
      <SubHeader
        :backButton="true"
        backRedirectionUrl="/case-dashboard"
        :customCrumbLists="customBreadCrumbs"
      />
    </div>
    <div id="dashboard_container"></div>
  </div>
</template>

<script>
import { BoldBI } from "@boldbi/boldbi-embedded-sdk";
import biHelper from "@/boldbi";
import boldBiConfig from "@/boldbi/config.js";
import VueCookies from "vue-cookies";
import SubHeader from "@/components/SubHeader";

function embedSample(dashboardId) {
  // const userEmail = this.$store.getters.getUser.email;
  let token = VueCookies.get("token");
  const boldBiHeader = {
    Authorization: "Bearer " + token,
  };

  var boldbiEmbedInstance = BoldBI.create({
    serverUrl: boldBiConfig.BASE_URL,
    dashboardId: dashboardId,
    embedContainerId: "dashboard_container", // This should be the container id where you want to embed the dashboard
    embedType: BoldBI.EmbedType.Component,
    environment: BoldBI.Environment.Enterprise,
    mode: BoldBI.Mode.Design,
    height: "100%",
    width: "100%",
    authorizationServer: {
      url: biHelper.generateEmbedUrl(),
      headers: boldBiHeader,
    },
    expirationTime: "100000",
  });
  boldbiEmbedInstance.loadDesigner();
}

export default {
  name: "Bi-Dashboard-Edit",
  props: {
    backButton: {
      type: Boolean,
      default: true,
    },
    backRedirectionUrl: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      customBreadCrumbs: [],
    };
  },
  components: {
    SubHeader,
  },
  mounted() {
    const dashboardId = this.$route.params.id;
    embedSample(dashboardId);
    this.getDashboardData(dashboardId);
  },
  methods: {
    handleBack() {
      this.$router.back();
    },
    async getDashboardData(dashboardId) {
      const data = await biHelper.getDashboardData(dashboardId);
      this.setBredCrumbs(data.Name);
    },

    setBredCrumbs(crumb) {
      this.customBreadCrumbs = [];
      if (crumb) {
        this.customBreadCrumbs.push({ name: crumb });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
#editPage {
  width: 100%;
  height: 100%;
}
#dashboard_container {
  width: 100%;
  height: 100%;
}
</style>
