<template>
  <div
    v-if="show"
    class="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-10"
  >
    <div class="flex items-start bg-white rounded-2xl shadow-md">
      <div>
        <div class="flex flex-row justify-between px-6 mt-4">
          <div class="flex flex-row items-center">
            <layerGroupSvg />
            <p class="font-semibold text-base ml-2">
              Let’s Start with a name for your dashboard
            </p>
          </div>

          <span @click="cancel" class="cursor-pointer"
            ><font-awesome-icon icon="times" size="xl" style="color: #49454fb2"
          /></span>
        </div>

        <hr class="border-t border-gray-300 my-5" />

        <div class="pl-6 pr-11">
          <input
            class="border border-red-800 border-solid rounded-lg px-4 py-2.5 w-full text-sm font-normal"
            type="text"
            :placeholder="placeholder"
            v-model="inputValue"
          />

          <p class="font-normal text-base mt-4 line-clamp-2">
            All dashboards are autosaved as drafts for you to continue where you
            left off. <br />Publishing a dashboard must be done manually.
          </p>
        </div>

        <div
          class="flex justify-end mt-4 text-right mb-5 px-6 border-t border-gray-300 pt-3"
        >
          <button
            @click="submit"
            class="px-3 py-2.5 bg-red-800 text-white font-semibold text-xs text-center rounded hover:bg-red-600 mr-2"
          >
            Add and Design
          </button>
          <button
            @click="cancel"
            class="px-3 py-2.5 bg-gray-200 text-gray-700 font-semibold text-xs text-center rounded hover:bg-gray-300"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import layerGroupSvg from "@/assets/icons/layer-group.svg";
export default {
  props: {
    show: Boolean,
    placeholder: String,
  },
  data() {
    return {
      inputValue: "",
    };
  },
  components: { layerGroupSvg },

  methods: {
    submit() {
      this.$emit("submit", this.inputValue);
      this.inputValue = "";
    },
    cancel() {
      this.$emit("cancel");
      this.inputValue = "";
    },
  },
};
</script>
