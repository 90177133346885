<template>
  <div id="datasource_container">
    <h1>Design Component</h1>
  </div>
</template>

<script>
import { BoldBI } from "@boldbi/boldbi-embedded-sdk";
import BoldBiConfig from "@/boldbi/config";
import bHelper from "@/boldbi";

function embedSample(datasourceId) {
  var boldbiEmbedInstance = BoldBI.create({
    serverUrl: BoldBiConfig.BASE_URL,
    datasourceId: datasourceId,
    embedContainerId: "datasource_container", // This should be the container id where you want to embed the datasource
    embedType: BoldBI.EmbedType.Component,
    environment: BoldBI.Environment.Enterprise,
    mode: BoldBI.Mode.DataSource,
    height: "90%",
    width: "100%",
    authorizationServer: {
      url: bHelper.generateEmbedUrl(),
    },
    expirationTime: "100000",
  });
  boldbiEmbedInstance.loadDatasource();
}

export default {
  name: "Bi-Data-Source",

  mounted() {
    const datasourceId = "de5a6bd0-35a1-49eb-984a-b2e69cc8d2f5";

    //const userEmail = "brij@example.com";
    embedSample(datasourceId);
  },
};
</script>
