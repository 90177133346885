<template>
  <div id="editPage">
    <div v-if="this.dashboardOptions.length>1" class="flex flex-row mb-2.5 justify-between" style="z-index:10000;">
      <!-- <SubHeader
        :backButton="true"
        backRedirectionUrl="/case-dashboard"
        :customCrumbLists="customBreadCrumbs"
      /> -->
       <dropdown
          reposition
          searchable
          class="filter-dropdown h-10 ml-auto"
          placeholder="Dashboards"
          :options="dashboardOptions"
          v-model="selectedDashboard"
          :colors="{
            text: '#282828',
            bg: 'white',
            svg: '#282828',
            border: '#e8e8e8',
          }"
          :limit="1"
          width="192px"
          minWidth="102px"
          :config="{ label: 'labelName', trackBy: 'dashboard_id' }"
          @input="selectDashboard"
        >
          <template #caret>
            <sort-icon />
          </template>
        </dropdown>
    </div>
    <div id="dashboard_container"></div>
  </div>
</template>

<script>
import { BoldBI } from "@boldbi/boldbi-embedded-sdk";
import BoldBiConfig from "@/boldbi/config";
import biHelper from "@/boldbi";
import VueCookies from "vue-cookies";
// import SubHeader from "@/components/SubHeader";
import dropdown from "@/components/dropdown-base";
import axios from "@/axios";

function embedSample(dashboardId) {
  let token = VueCookies.get("token");
  const boldBiHeader = {
    Authorization: "Bearer " + token,
  };

  var boldbiEmbedInstance = BoldBI.create({
    serverUrl: BoldBiConfig.BASE_URL,
    dashboardId: dashboardId,
    embedContainerId: "dashboard_container", // This should be the container id where you want to embed the dashboard
    embedType: BoldBI.EmbedType.Component,
    environment: BoldBI.Environment.Enterprise,
    mode: BoldBI.Mode.View,
    height: "100%",
    width: "100%",
    authorizationServer: {
      url: biHelper.generateEmbedUrl(),
      headers: boldBiHeader,
    },
    expirationTime: "100000",
  });
  console.log("boldbiEmbedInstance", boldbiEmbedInstance);
  boldbiEmbedInstance.loadDashboard();
}

export default {
  name: "Bi-Dashboard-View",
  props: {
    backButton: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      customBreadCrumbs: [],
      dashboardOptions: [],
      selectedDashboard: null
    };
  },
  components: {
    // SubHeader,
    dropdown
  },

  async mounted() {
    await this.getTenantDashboards()
    const dashboardId = this.$route.params.id;
    embedSample(dashboardId);
    this.getDashboardData(dashboardId);
  },
  methods: {
    async getTenantDashboards() {
      try {
        const { data } = await axios.get("/dashboard/assigned-dashboards");

        this.dashboardOptions = data.dashboards.map((dashboard) => {
          let bbDashboard = dashboard;
          if (dashboard.name.includes("BB_")) {
            bbDashboard.labelName = dashboard.name.split("_")[1];
          } else {
            bbDashboard.labelName = dashboard.name;
          }

          bbDashboard.name = dashboard.name;
          return bbDashboard;
        });
        this.selectedDashboard = this.dashboardOptions[0];
        this.$router.push({params:{id: this.selectedDashboard.dashboard_id}})
      } catch (err) {
        console.log(err);
      }
    },
    async selectDashboard() {
      if (
        this.selectedDashboard?.dashboard_id !=
        process.env.VUE_APP_DEFAULT_DASHBOARD_KEY
      ) {
        this.dashboardLoaded = false;
        this.$router.push({params:{id: this.selectedDashboard.dashboard_id}})
        embedSample(this.$route.params.id);
        this.getDashboardData(this.$route.params.id);
      } else if (
        this.selectedDashboard?.dashboard_id ==
        process.env.VUE_APP_DEFAULT_DASHBOARD_KEY
      ) {
        this.$router.push('/default-dashboard')
      }
    },
    // Function to adjust the iframe height
    adjustIframeHeight() {
      var iframe = document.getElementById("dashboard-frame");
      if (iframe) {
        iframe.height = Math.max(document.documentElement.scrollHeight) + "px";
      }
    },
    async getDashboardData(dashboardId) {
      const data = await biHelper.getDashboardData(dashboardId);
      this.setBredCrumbs(data.Name);
    },

    setBredCrumbs(crumb) {
      this.customBreadCrumbs = [];
      if (crumb) {
        this.customBreadCrumbs.push({ name: crumb });
      }
    },
  },
};
</script>
<style scoped>
#dashboard_container {
  width: "100%";
}

#list {
  padding: 10px;
  border-radius: 8px;
  border: 1px solid rgba(218, 218, 218, 0.87);
  background: #fff;
  margin-bottom: 12px;
}

#editPage {
  width: 100%;
  height: 100%;
}
#dashboard_container {
  width: 100%;
  height: 100%;
  z-index: 0;
  position: relative;
}
</style>
