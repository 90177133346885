<template>
  <div
    v-if="show"
    class="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-10"
  >
    <div class="bg-white p-4 rounded shadow-md">
      <div class="px-16">
        <div class="flex justify-center mt-4"><dangerSvg /></div>
        <p class="font-medium text-sm mt-4">Are you sure you want to Delete?</p>
        <div class="flex justify-center mt-4 text-right mb-5">
          <button
            @click="confirmDelete"
            class="px-3 py-3 bg-blue-500 text-white font-semibold text-xs rounded hover:bg-blue-600 mr-2"
          >
            Yes
          </button>
          <button
            @click="cancelDelete"
            class="px-3 py-3 bg-gray-200 text-gray-700 font-semibold text-xs rounded hover:bg-gray-300"
          >
            No
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dangerSvg from "@/assets/icons/danger.svg";
export default {
  props: {
    show: Boolean,
  },
  components: { dangerSvg },

  methods: {
    cancelDelete() {
      this.$emit("cancel");
    },
    confirmDelete() {
      this.$emit("confirm");
    },
  },
};
</script>
