<template>
  <div
    class="timeline"
    ref="container"
    :style="{ padding: containerPadding + 'px' }"
  >
    <delvium-loader style="translate: 0 0" v-if="loading" />
    <template v-else>
      <div class="header">
        <!-- <img v-if="data.header.icon" class="icon" :src="data.header.icon" alt="">
         -->
        <AddressIcon v-if="type === 'address'" />
        <ChecksIcon v-if="type === 'checks'" />
        <gapIcon v-if="type === 'gap'" />
        <statusIcon v-if="type === 'status'" />

        <div class="label text-base-content">{{ data.header.label }}</div>
      </div>
      <div class="body">
        <div
          class="body__content"
          ref="content"
          v-if="timeline && timeline.length > 0"
          :style="{ gap: `${contentGap}px` }"
        >
          <div
            v-for="(point, idx) in timeline"
            :key="point.key + idx"
            class="pointData"
            :class="point.title != 'Unaccounted' ? 'cursor-pointer': ''"
            ref="pointData"
            @click="redirectToCheck(point)"
          >
            <div v-if="showDates" class="mark" ref="mark">
              <div class="mark__icon-wrapper">
                <div class="mark__line"></div>
                <div
                  class="mark__icon"
                  :style="{
                    width: pointWidth + 'px',
                    height: pointWidth + 'px',
                  }"
                ></div>
              </div>
              <!-- <div class="mark__date text-base-content">{{ point.year }}-{{ point.month }}-{{ point.day }}</div> -->
              <div class="mark__date text-base-content">{{ point.fromDate }}</div>
            </div>
            <div
              class="pointData__content"
              :style="{ borderColor: point.color }"
            >
              <div class="pointData__header" :style="{ color: point.color }">
                <!-- <img v-if="point.icon" class="icon" :src="point.icon" alt="">
                         -->
                <!-- <svg
                  width="20"
                  class="icon"
                  height="24"
                  viewBox="0 0 20 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.7778 12.6436L13.7778 16.8764L10 14.64L6.22222 16.8764L7.22222 12.6545L3.88889 9.82909L8.28889 9.45818L10 5.47636L11.7111 9.44727L16.1111 9.81818L12.7778 12.6436ZM10 2.38909L17.7778 5.78182V10.9091C17.7778 15.84 14.4667 20.3891 10 21.7418C5.53333 20.3891 2.22222 15.84 2.22222 10.9091V5.78182L10 2.38909ZM10 0L0 4.36364V10.9091C0 16.9636 4.26667 22.6255 10 24C15.7333 22.6255 20 16.9636 20 10.9091V4.36364L10 0Z"
                    fill="#05BEF9"
                  />
                </svg> -->

                <div class="label break-all">{{ point.title }}</div>
              </div>
              <div v-if="point.description" class="pointData__alert">
                {{ point.description }}
              </div>
              <div v-if="point.type" class="pointData__alert">
               {{ point.fromDate }}-{{ point.toDate }}
              </div>
            </div>
          </div>
        </div>
        <template v-else>
          <div class="flex-1">
            <Empty />
          </div>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import AddressIcon from "@shared/assets/icon/svgs/address.svg";
import ChecksIcon from "@shared/assets/icon/svgs/checks.svg";
import gapIcon from "@shared/assets/icon/svgs/gap.svg";
import statusIcon from "@shared/assets/icon/svgs/status.svg";
import Empty from "@shared/empty";
import DelviumLoader from "@shared/components/loader";
import { DateTime } from "luxon";

export default {
  components: {
    AddressIcon,
    ChecksIcon,
    gapIcon,
    statusIcon,
    Empty,
    DelviumLoader,
  },
  name: "timeline",
  props: {
    data: {
      type: Object,
      required: true,
    },
    showDates: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: "status",
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      rowCount: 1,
      contentGap: 40,
      pointWidth: 20,
      containerPadding: 20,
    };
  },
  computed: {
    timeline() {
      const formatDate = (date) => DateTime.fromISO(date).toFormat("dd MMM, yyyy");
      if (this.data.timeline && this.data.timeline.length > 0) {
        return [...this.data.timeline]
          .sort((a, b) => a.date - b.date)
          .map((el) => ({
            ...el,
            key: el.date.toDateString(),
            day: el.date.getDate().toString().padStart(2, "0"),
            month: (el.date.getMonth() + 1).toString().padStart(2, "0"),
            year: el.date.getFullYear(),
            fromDate: el?.orignalDate && formatDate(el.orignalDate),
            toDate: el?.toDate ? formatDate(el?.toDate) : null,
          }));
      } else return [];
    },
  },
  created() {
    window.addEventListener("resize", this.setTimelineSize);
  },
  mounted() {
    this.setTimelineSize();
  },
  destroyed() {
    window.removeEventListener("resize", this.setTimelineSize);
  },
  methods: {
    setTimelineSize() {
      if (
        !this.showDates &&
        !this.$refs.pointData &&
        !this.$refs.pointData?.length
      )
        return;
      this.$refs.pointData?.forEach((point, i) => {
        this.$nextTick(() => {
          if(i <=  this.$refs?.mark?.length-1) {
            const mark = this.$refs?.mark[i];
            const markLine = mark?.querySelector(".mark__line");
            const containerX = parseInt(this.$refs?.container?.getBoundingClientRect().x, 10);
            const pointX = parseInt(point?.getBoundingClientRect().x, 10);
            const isFirstInLine = containerX + this.containerPadding === pointX;
            if (!isFirstInLine) {
              const pointWidth = point.getBoundingClientRect().width;
              const marginBase = pointWidth / 2;
              const marginLeft = marginBase + (i ? this.contentGap : 0);
              const prevPoint = this.$refs.pointData.length ? this.$refs.pointData[i - 1] : "";
              const prevPointWidth = prevPoint.getBoundingClientRect().width;
              let lineWidth = marginLeft + prevPointWidth / 2;
              markLine.style.width = lineWidth - this.pointWidth + "px";
            } else {
              markLine.style.width = 0;
            }
          }
        })
      });
    },

    redirectToCheck(point) {
      if (point.id && point.check_id) {
        this.$emit('redirectToCheck', point)
      } else if(point.check_id) {
        this.$emit('redirectToCheck', point, "timeline")
      }
    }
  },
};
</script>

<style lang="scss" scoped>
$color-timeline-primary: #3b2cd4;
$color-timeline-secondary: #cce2f6;

* {
  box-sizing: border-box;
}
.timeline {
  border-radius: 12px;
  // background-color: #F2F2F2;
  padding: 24px;
  .header {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 17px;
    font-weight: bold;
    .icon {
      width: 24px;
      height: 24px;
    }
  }
  .body {
    &__content {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      .pointData {
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        // .mark-wrapper {
        //     width: 0;
        //     margin-bottom: 20px;
        // }
        .mark {
          display: inline-flex;
          flex-direction: column;
          align-items: center;
          gap: 12px;
          margin-bottom: 20px;
          .mark__icon-wrapper {
            position: relative;
          }
          &__icon {
            border-radius: 50%;
            background-color: $color-timeline-primary;
            border: 5px solid $color-timeline-secondary;
          }
          &__line {
            width: 0;
            height: 2px;
            background-color: $color-timeline-primary;
            position: absolute;
            top: 50%;
            right: 100%;
          }
          &__date {
            font-weight: bold;
            font-size: 12px;
            line-height: 18px;
            white-space: nowrap;
          }
        }
        &__content {
          display: inline-flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          gap: 10px;
          padding: 12px;
          min-width: 160px;
          max-width: 200px;
          min-height: 60px;
          border-radius: 8px;
          border: 2px solid $color-timeline-primary;
          background-color: #fff;
        }
        &__header {
          display: flex;
          align-items: center;
          line-height: 24px;
          font-size: 14px;
          font-weight: bold;
          gap: 16px;
          .icon {
            width: 24px;
            height: 24px;
          }
        }
        &__alert {
          font-weight: bold;
          font-size: 12px;
        }
      }
    }
  }
}
</style>
