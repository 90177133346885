<template>
  <div class="test-page page1">
    <div class="flex items-center justify-center py-24" v-if="!dashboardLoaded">
      <Loader />
    </div>
    <div id="dashboard_container" v-if="dashboardLoaded">
      <div class="flex justify-between">
        <div class="flex flex-row">
          <div
            @click="handleShiftTab('all')"
            class="p-2 mr-3 border-zinc-400 bg-white rounded-lg text-xs font-medium text-gray-600 cursor-pointer"
            :class="tabClasses('all')"
          >
            Published
          </div>
          <div
            @click="handleShiftTab('favorite')"
            class="p-2 mr-3 border-slate-600 bg-white rounded-lg text-xs font-medium text-gray-600 cursor-pointer"
            :class="tabClasses('favorite')"
          >
            Favourites
          </div>
          <div
            @click="handleShiftTab('draft')"
            class="p-2 mr-3 border-slate-600 bg-white rounded-lg text-xs font-medium text-gray-600 cursor-pointer"
            :class="tabClasses('draft')"
          >
            Drafts
          </div>
        </div>
        <div
          v-if="checkPermission('write')"
          class="flex flex-row items-center p-2 mr-3 border-zinc-400 bg-red-900 text-white rounded-lg cursor-pointer"
          @click="showCreateDashboard"
        >
          <p class="text-xs font-semibold mr-2">New Dashboard</p>
          <font-awesome-icon icon="plus" size="sm" />
        </div>
      </div>
      <hr class="border-t border-gray-300 my-2.5" />

      <div v-if="selectedTab === 'all'">
        <ul>
          <!-- Use v-for to iterate over the list -->

          <li v-for="(item, index) in dashboardList" :key="index">
            <div
              class="flex items-center bg-white p-2.5 mb-3 min-h-86 bg-white border border-solid border-gray-300 rounded-lg w-full"
            >
              <div class="w-full">
                <div class="flex flex-row items-center justify-between">
                  <div class="flex flex-row">
                    <div
                      v-if="
                        item.IsFavorite && item.Name !== 'Default Dashboard'
                      "
                      @click="bookmark(item.Id, item.IsFavorite)"
                      class="cursor-pointer mr-3"
                    >
                      <bookmarkFillSvg />
                    </div>
                    <div
                      v-else-if="item.Name !== 'Default Dashboard'"
                      @click="bookmark(item.Id, item.IsFavorite)"
                      class="cursor-pointer mr-3"
                    >
                      <bookmarkSvg />
                    </div>

                    <span
                      class="font-semibold text-sm cursor-pointer"
                      @click="handleView(item)"
                      >{{ item.Name }}</span
                    >
                  </div>
                  <div class="flex flex-row">
                    <!-- <div
                      v-if="checkPermission('read')"
                      @click="handleView(item)"
                      class="cursor-pointer mr-3"
                    >
                      <viewSvg
                        :fill="
                          item.Name == 'Default Dashboard'
                            ? '#2B2B2B'
                            : '#2B2B2B'
                        "
                      />
                    </div> -->

                    <button
                      @click="handleView(item)"
                      :disabled="!checkPermission('read')"
                      class="border-none mr-3"
                    >
                      <viewSvg
                        :fill="
                          item.Name == 'Default Dashboard'
                            ? '#2B2B2B'
                            : '#2B2B2B'
                        "
                      />
                    </button>

                    <button
                      @click="handleEdit(item.Id)"
                      :disabled="!isEditDisabled(item.Name)"
                      class="border-none mr-3"
                      :class="{ 'opacity-20': !isEditDisabled(item.Name) }"
                    >
                      <editSvg />
                    </button>

                    <button
                      @click="showDeleteModal(item.Id)"
                      :disabled="!isDeleteDisabled(item.Name)"
                      class="border-none mr-3"
                      :class="{ 'opacity-20': !isDeleteDisabled(item.Name) }"
                    >
                      <deleteSvg />
                    </button>
                  </div>
                </div>

                <hr class="border-t border-gray-300 my-3" />
                <div class="flex flex-row items-center text-gray-600 text-xs">
                  <!-- <div class="mr-1"><userSvg /></div>
                  {{ item.CreatedByDisplayName }}
                  <span class="mx-1">|</span> -->
                  <span class="mr-1"
                    ><timerSvg
                      height="16px"
                      width="16px"
                      fill="#2B2B2B" /></span
                  >{{ item.CreatedDate }}
                </div>
              </div>
            </div>
          </li>
        </ul>
        <div
          v-if="dashboardList.length !== 0"
          class="flex items-center justify-end flex-row font-medium text-xs"
        >
          <div class="mr-1 text-gray-700">
            {{ page_start !== 1 ? page_start + 1 : page_start }} &nbsp;-
            &nbsp;{{ page_end + 1 }}
            of
            {{ totalResult + 1 }}
          </div>

          <button
            @click="previous"
            :disabled="isPreviousDisabled"
            class="text-gray border-none font-medium text-xs ml-1 mr-3"
            :class="{
              'text-gray-500': isPreviousDisabled,
              'text-gray-900': !isPreviousDisabled,
            }"
          >
            <font-awesome-icon class="mr-2" icon="caret-left" /> Previous
          </button>

          <button
            @click="next"
            :disabled="isNextDisabled"
            class="flex items-center text-gray border-none font-medium text-xs ml-1 mr-3"
            :class="{
              'text-gray-500': isNextDisabled,
              'text-gray-900': !isNextDisabled,
            }"
          >
            Next <font-awesome-icon class="ml-2" icon="caret-right" />
          </button>
        </div>
      </div>
      <div v-else-if="selectedTab === 'favorite'">
        <ul>
          <!-- Use v-for to iterate over the list -->

          <li v-for="(item, index) in favoriteDashboardList" :key="index">
            <div
              class="flex items-center bg-white p-2.5 mb-3 border border-solid border-gray-300 rounded-lg w-full"
            >
              <div class="w-full">
                <div class="flex flex-row items-center justify-between">
                  <div class="flex flex-row">
                    <span
                      @click="handleView(item)"
                      class="font-semibold text-sm cursor-pointer"
                      >{{ item.Name }}</span
                    >
                  </div>
                  <div class="flex flex-row">
                    <button
                      @click="handleView(item)"
                      :disabled="!checkPermission('read')"
                      class="border-none mr-3"
                    >
                      <viewSvg :fill="'#2B2B2B'" />
                    </button>

                    <button
                      @click="handleEdit(item.DashboardId)"
                      :disabled="!isEditDisabled(item.Name)"
                      class="border-none mr-3"
                      :class="{ 'opacity-20': !isEditDisabled(item.Name) }"
                    >
                      <editSvg />
                    </button>

                    <button
                      @click="showDeleteModal(item.DashboardId)"
                      :disabled="!isDeleteDisabled(item.Name)"
                      class="border-none mr-3"
                      :class="{ 'opacity-20': !isDeleteDisabled(item.Name) }"
                    >
                      <deleteSvg />
                    </button>
                  </div>
                </div>
                <hr class="border-t border-gray-300 my-3" />
                <div class="flex flex-row items-center text-gray-600 text-xs">
                  <!-- <div class="mr-1"><userSvg /></div>
                  {{ item.CreatedByDisplayName }}
                  <span class="mx-1">|</span> -->
                  <span class="mr-1"
                    ><timerSvg
                      height="16px"
                      width="16px"
                      fill="#2B2B2B" /></span
                  >{{ item.CreatedDate }}
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div v-else-if="selectedTab === 'draft'">
        <ul>
          <!-- Use v-for to iterate over the list -->

          <li v-for="(item, index) in draftDashboardList" :key="index">
            <div
              class="flex items-center bg-white p-2.5 mb-3 border border-solid border-gray-300 rounded-lg w-full"
            >
              <div class="w-full">
                <div class="flex flex-row items-center justify-between">
                  <div class="flex flex-row">
                    <span
                      @click="handleView(item)"
                      class="font-semibold text-sm cursor-pointer"
                      >{{ item.Name }}</span
                    >
                  </div>
                  <div class="flex flex-row">
                    <!-- <div
                      v-if="checkPermission('write')"
                      @click="handleDraftEdit(item)"
                      class="cursor-pointer mr-3"
                    >
                      <editSvg />
                    </div>
                    <div
                      v-if="checkPermission('delete')"
                      @click="showDeleteModal(item.Id)"
                      class="cursor-pointer"
                    >
                      <deleteSvg />
                    </div> -->

                    <button
                      @click="handleEdit(item.Id)"
                      :disabled="!isEditDisabled(item.Name)"
                      class="border-none mr-3"
                      :class="{ 'opacity-20': !isEditDisabled(item.Name) }"
                    >
                      <editSvg />
                    </button>

                    <button
                      @click="showDeleteModal(item.Id)"
                      :disabled="!isDeleteDisabled(item.Name)"
                      class="border-none mr-3"
                      :class="{ 'opacity-20': !isDeleteDisabled(item.Name) }"
                    >
                      <deleteSvg />
                    </button>
                  </div>
                </div>
                <hr class="border-t border-gray-300 my-3" />
                <div class="flex flex-row items-center text-gray-600 text-xs">
                  <!-- <div class="mr-1"><userSvg /></div>
                  {{ item.CreatedByDisplayName }}
                  <span class="mx-1">|</span> -->
                  <span class="mr-1"
                    ><timerSvg
                      height="16px"
                      width="16px"
                      fill="#2B2B2B" /></span
                  >{{ item.CreatedDate }}
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <!-- Include the delete confirmation popup component -->
    <deleteConfirmationModal
      :show="isDeleteModalVisible"
      @cancel="hideDeleteModal"
      @confirm="handleDelete"
    />
    <createDashboard
      :show="isCreateDashboard"
      placeholder="Untitled Dasboard"
      @cancel="showCreateDashboard"
      @submit="handleCreateDashboard"
    />
  </div>
</template>

<script>
import deleteConfirmationModal from "@/components/popup";
import viewSvg from "@/assets/icons/view.svg";
import timerSvg from "@/assets/icons/timer.svg";
import editSvg from "@/assets/icons/edit.svg";
import deleteSvg from "@/assets/icons/delete.svg";
//import userSvg from "@/assets/icons/user-icon.svg";
import bookmarkSvg from "@/assets/icons/bookmark.svg";
import bookmarkFillSvg from "@/assets/icons/bookmark-fill.svg";
import bHelper from "@/boldbi";
import createDashboard from "@/components/create-dashboard";
import axios from "@/axios";
import Loader from "@/components/loader";
export default {
  name: "bi-dashboard",
  components: {
    viewSvg,
    timerSvg,
    editSvg,
    deleteSvg,
    bookmarkSvg,
    deleteConfirmationModal,
    createDashboard,
    bookmarkFillSvg,
    Loader,
  },

  data() {
    return {
      dashboardList: [],
      draftDashboardList: [],
      favoriteDashboardList: [],
      isDeleteModalVisible: false,
      selectedDashboardId: null,
      page: 1,
      page_size: 4,
      totalResult: null,
      isCreateDashboard: false,
      selectedTab: "all",
      page_start: null,
      page_end: null,
      userEmail: this.$store.getters.getUser.email,
      localToken: {},
      dashboardLoaded: false,
    };
  },
  async mounted() {
    this.dashboardListData(this.page, this.page_size);
    // this.draftDashboardListData();
    // this.dashboardFavoriteListData();
  },

  updated() {},
  computed: {
    isPreviousDisabled() {
      return this.page === 1;
    },
    isNextDisabled() {
      var totalPages = Math.ceil(this.totalResult / this.page_size);
      return this.page === totalPages;
    },
  },
  methods: {
    tabClasses(tabName) {
      return {
        "bg-red-50": this.selectedTab === tabName,
        "text-red-800": this.selectedTab === tabName,
        "text-dbrand": this.selectedTab !== tabName,
        "hover:bg-red-50": this.selectedTab !== tabName,
        "hover:text-red-800": this.selectedTab !== tabName,
      };
    },
    updatePageStart() {
      this.page_start = Math.max(
        Math.min((this.page - 1) * this.page_size + 1, this.totalResult),
        1
      );
    },
    updatePageEnd() {
      this.page_end = Math.max(
        Math.min(this.page * this.page_size, this.totalResult),
        1
      );
    },
    updateDashboardList(dashboardList) {
      this.dashboardList = dashboardList;
    },

    checkPermission(permissionType) {
      return this.localToken?.permission?.includes(permissionType);
    },

    handleShiftTab(tabName) {
      this.selectedTab = tabName;
      if (tabName == "all") {
        this.dashboardListData(this.page, this.page_size);
      }
      if (tabName == "favorite") {
        this.dashboardFavoriteListData();
      }
      if (tabName == "draft") {
        this.draftDashboardListData();
      }
    },

    async dashboardListData(page, page_size) {
      try {
        let defaultlistData = [];
        if (this.page == 1) {
          const { data } = await axios.get("/dashboard/assigned-dashboards");
          const defaultData = {
            CanRead: true,
            CanWrite: true,
            CanDelete: true,
            CanDownload: true,
            CanSchedule: false,

            CanOpen: true,

            CanMove: true,

            CanCopy: true,

            CanClone: true,

            CanCreateItem: false,

            Id: data.dashboards[0].dashboard_id,

            ItemType: "Dashboard",

            Name: data.dashboards[0].name,

            Description: "",

            CreatedById: 85,

            CreatedByDisplayName: "-",

            ModifiedById: 85,

            ModifiedByFullName: "Default Dashboard",

            CategoryId: "",

            CategoryName: "",

            CreatedDate: "-",

            ModifiedDate: "-",

            ItemModifiedDate: "-",

            ItemCreatedDate: "-",

            IsMultiDashboard: false,

            IsFavorite: false,

            IsPublic: false,

            IsLocked: false,

            EmbedUrl: "",

            IsUnlisted: false,
          };
          defaultlistData = [defaultData];
        }
        try {
          const dlist = await bHelper.getDashboardList(page, page_size);
          this.localToken = JSON.parse(bHelper.getBiLocalStorageData());
          if (this.page == 1) {
            this.updateDashboardList([...defaultlistData, ...dlist.Data]);
          } else {
            this.updateDashboardList(dlist.Data);
          }
          this.totalResult = dlist.TotalResults;
          this.dashboardLoaded = true;
        } catch (error) {
          this.$toast.error(error.Message);
          if (defaultlistData.length == 1) {
            this.updateDashboardList(defaultlistData);
          }
          this.dashboardLoaded = true;
        }

        this.updatePageStart();
        this.updatePageEnd();
      } catch (error) {
        this.$toast.error(error.Message);
        this.dashboardLoaded = true;
      }
    },

    async draftDashboardListData() {
      const dlist = await bHelper.draftDashboardList();
      this.draftDashboardList = dlist;
    },

    async dashboardFavoriteListData() {
      const dlist = await bHelper.dashboardFavoriteList();
      this.favoriteDashboardList = dlist;
      this.dashboardListData(this.page, this.page_size);
    },

    handleView(dashboardData) {
      if (dashboardData.Name == "Default Dashboard") {
        this.$router.push({ name: "Dashboard - Page 2" });
      } else {
        this.$router.push({
          name: "Bi-Dashboard-View",
          params: {
            id: dashboardData.Id || dashboardData.DashboardId,
          },
        });
      }
    },

    handleEdit(Id) {
      this.$router.push({
        name: "Bi-Dashboard-Edit",
        params: { id: Id },
      });
    },

    async handleDelete() {
      try {
        this.hideDeleteModal();
        await bHelper.deleteDashboard(this.selectedDashboardId);
        this.dashboardListData(this.page, this.page_size);
        this.draftDashboardListData();
        this.dashboardFavoriteListData();
      } catch (error) {
        this.$toast.error(error.Message);
      }
    },

    showDeleteModal(Id) {
      this.isDeleteModalVisible = true;
      this.selectedDashboardId = Id;
    },

    hideDeleteModal() {
      this.isDeleteModalVisible = false;
    },

    showCreateDashboard() {
      this.isCreateDashboard = !this.isCreateDashboard;
    },

    validateName(name) {
      const minLength = 2;
      const maxLength = 100;
      name = name.trim();
      if (!name) {
        this.$toast.error("Name is required.");
        return false;
      }

      if (name.length < minLength || name.length > maxLength) {
        this.$toast.error(
          `Name must be between ${minLength} and ${maxLength} characters.`
        );
        return false;
      }

      return true; // Name is valid
    },

    async handleCreateDashboard(inputValue) {
      try {
        if (this.validateName(inputValue)) {
          const createdata = await bHelper.createDraftDashboard(inputValue);
          this.showCreateDashboard();
          this.draftDashboardListData();
          this.handleShiftTab("draft");
          this.handleEdit(createdata.Id);
        }
      } catch (error) {
        this.$toast.error(error.Message);
      }
    },
    handleDraftEdit(item) {
      this.$router.push({
        name: "Bi-Dashboard-Edit",
        params: { id: item.Id },
      });
    },
    async bookmark(Id, favorite) {
      await bHelper.markDashboardFavorite(Id, !favorite);
      this.dashboardListData(this.page, this.page_size);
      this.dashboardFavoriteListData();
    },
    next() {
      if (this.page_start + this.page_size > this.totalResult) {
        return;
      }
      this.page = this.page + 1;
      this.dashboardListData(this.page, this.page_size);
      this.updatePageStart();
      this.updatePageEnd();
    },

    previous() {
      if (this.page_start == 1) {
        return;
      }

      this.page = this.page - 1;
      this.dashboardListData(this.page, this.page_size);
      this.updatePageStart();
      this.updatePageEnd();
    },
    isEditDisabled(name) {
      const editButton =
        this.checkPermission("write") && name !== "Default Dashboard";
      return editButton;
    },
    isDeleteDisabled(name) {
      const deleteButton =
        this.checkPermission("delete") && name !== "Default Dashboard";
      return deleteButton;
    },
  },
};
</script>

<style lang="scss" scoped>
#dashboard_container {
  width: "100%";
}
.borderDefaultDashboard {
  border-color: var(--theme-color-selection);
  //border-width: 2px;
}
</style>
